<template>
  <v-container fluid id="regular-table">
    <v-form ref="form" v-model="is_valid" lazy-validation>
      <div class="ma-3 d-flex flex-column flex-md-row" style="gap: 30px">
        <v-flex xs6>
          <h3 class="text-h4 mb-4">{{ $t("transfer_details") }}</h3>
          <div class="ma-4">
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("origin_hub") }}</span>
              <span class="text-h4">
                {{ transfer?.fromHub?.name ?? "-" }}
              </span>
            </div>
            <v-divider></v-divider>
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("destination_hub") }}</span>
              <span class="text-h4">
                {{ transfer?.toHub?.name ?? "-" }}
              </span>
            </div>

            <v-divider></v-divider>
            <div
              class="text-h4 d-flex justify-space-between py-4"
              style="min-width: 200px"
            >
              <span class="mt-2">{{ $t("transfer_note") }}</span>
              <v-btn
                small
                :loading="isLoading"
                rounded
                class="primary"
                text
                @click="downloadDeliveryNot(transfer)"
              >
                {{ "transfer-note-" + transfer.tracking_number + ".pdf" }}
                <v-icon class="ma-2"> mdi-download </v-icon>
              </v-btn>
            </div>
            <template v-if="transfer?.transferStatus?.name != 'open'">
              <v-divider></v-divider>
              <div class="text-h4 d-flex justify-space-between py-4">
                <span>{{ $t("status") }}</span>
                <span class="text-h4">
                  {{ transfer?.transferStatus?.name.toUpperCase() ?? "-" }}
                </span>
              </div>
            </template>
            <v-divider></v-divider>
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("delivery_date") }}</span>
            </div>

            <div class="d-flex flex-row align-end">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="transfer.expected_delivery_date"
                    readonly
                    label="Delivery date"
                    hide-details="auto"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    :rules="[rules.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  class="my-0 py-2"
                  v-model="transfer.expected_delivery_date"
                  no-title
                  :min="getTodayDate()"
                  @input="date_menu = false"
                ></v-date-picker>
              </v-menu>
              <dir class="mb-1 ml-2">
                <label class="mx-2"
                  >{{ $t("last_edited") + " : " + transfer.updated_at }}
                </label>
                <a text @click="show_details = !show_details">
                  {{ show_details ? $t("hide_details") : $t("show_details") }}
                  <v-icon>{{
                    show_details ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </a>
              </dir>
            </div>
            <v-expand-transition>
              <v-col cols="8" v-show="show_details">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t("previous_date") }}</th>
                        <th class="text-left">{{ $t("edit_date") }}</th>
                        <th class="text-left">{{ $t("modified_by") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="history in transfer.history"
                        :key="history.previous_date"
                      >
                        <td>{{ history.previous_date }}</td>
                        <td>{{ history.edit_date }}</td>
                        <td>{{ history.modified_by }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-expand-transition>
          </div>
        </v-flex>
      </div>
      <div>
        <v-card>
          <v-card-title class="text-h4">
            Item List ({{ transfer.products_count }})
          </v-card-title>
          <br />
          <div class="ms-5">
            <v-data-table
              :items="transfer.products"
              :headers="headers"
              item-key="id"
              class="elevation-0"
            >
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.image"
                  :src="item.image.url"
                  max-height="50"
                  max-width="50"
                />
                <v-img
                  v-else
                  src="@/assets/product.png"
                  max-height="50"
                  max-width="50"
                />
              </template>

              <template v-slot:item.qte_in_transfer="{ item }">
                <v-text-field
                  v-if="$admin.can('transfer-update')"
                  class="my-1"
                  outlined
                  dense
                  hide-details="auto"
                  type="number"
                  v-model="item.qte_in_transfer"
                  :rules="[
                    rules.min_quantity(0),
                    rules.max_quantity(item.qte_in_hub),
                  ]"
                ></v-text-field>
                <span v-else>{{ item.qte_in_transfer }}</span>
              </template>
              <template v-slot:item.purchase_price="{ item }">
                {{
                  Number(
                    item.purchase_price_ht *
                      (1 + parseFloat(item.purchase_tva) / 100)
                  )?.toFixed(2)
                }}
              </template>
            </v-data-table>
          </div>
        </v-card>

        <div class="mt-8 d-flex">
          <v-btn outlined class="ml-auto primary--text" @click="close()">
            {{ $t("back") }}
          </v-btn>

          <v-btn
            class="ml-4 primary"
            :disabled="!is_valid"
            @click="saveChange()"
          >
            {{ $t("save_change") }}
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import transferService from "@/store/services/transfer-product-service";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      transfer: "transferProducts/transfer",
    }),
  },

  data() {
    return {
      isLoading: false,
      is_valid: true,
      show_details: false,
      date_menu: false,
      headers: [
        { text: this.$t("image"), value: "image", align: "start" },
        {
          text: this.$t("name"),
          sortable: false,
          value: "name",
        },
        { text: this.$t("quantity"), value: "qte_in_transfer" },
        { text: this.$t("purchase_price_ht"), value: "purchase_price_ht" },
        { text: this.$t("tva"), value: "purchase_tva" },
        { text: this.$t("purchase_price"), value: "purchase_price" },
      ],
    };
  },

  methods: {
    async downloadDeliveryNot(transfer) {
      this.isLoading = true;
      const BDC = await transferService.getDeliveryNote(transfer);
      const url = window.URL.createObjectURL(new Blob([BDC]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${transfer.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
      this.isLoading = false;
    },

    close() {
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");

      this.$router.push("/transfers");
    },

    async saveChange() {
      try {
        if (this.$refs.form.validate()) {
          this.is_loading = true;
          console.log(this.transfer);
          const productsList = this.transfer.products.map((item) => {
            return {
              id: item.id,
              qte_in_transfer: item.qte_in_transfer,
              product_id: item.id,
            };
          });
          const data = {
            id: this.transfer.id,
            products: productsList,
            expected_delivery_date: this.transfer.expected_delivery_date,
          };
          await transferService.updateOutgoing(data);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.close();
          this.is_loading = false;
        }
      } catch (error) {
        console.log(error);
        this.is_loading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    getTodayDate() {
      return new Date().toJSON().slice(0, 10);
    },
  },

  async created() {
    const id = this.$route.params.id;
    if (id) {
      const transferEntity = await transferService.show({
        id,
      });

      this.$store.commit("transferProducts/TRANSFER", transferEntity);
      this.$store.commit("SET_PAGE_SUBTITLE", transferEntity.tracking_number);
      this.$store.commit("SET_SUBTITLE_ID", "Transfer ID :");
    }
  },
};
</script>